
export const PageSubtitle = () => {

    return(
        <>
            <div className="page-subtitle">
                <span>Muddassir Shaikh - Architect</span>
            </div>
        </>
    )
}
