/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { imgCategories } from "../assets/images"
import { GalleryFilterItems } from "./GalleryFilterItems"

export const GalleryFilters = ({handleFilterKeyChange, activeFilter}) => {
// console.log("activeFilter " + activeFilter)
    return (
        <>
        <div className="gallery-filters">
            {
                imgCategories.map((category, index) => {
                    // console.log(category.toString())
                    const defaultClasses = "gallery-filter transition2 "
                    let currentClassName = defaultClasses + (category.replace(" ", "-") == activeFilter ? "gallery-filter-active" : "");
                    // console.log("class : " + currentClassName)
                    return (
                        <GalleryFilterItems 
                            key={category.replace(" ", "-")}
                            categoryName={category}
                            handleFilterKeyChange={handleFilterKeyChange}
                            count={index+1}
                            currentClassName={currentClassName}
                        />
                    )
                })
            }
        </div>
        </>
    )
}

/* <a onClick={() => onClick("BUNGALOWS")} className="gallery-filter transition2 gallery-filter-active"><span>01. </span> Bungalow</a>
           <a onClick={() => onClick("APARTMENTS")} className="gallery-filter transition2" ><span>02. </span>Apartment</a>
            <a onClick={() => onClick("COMMUNITY-CENTER")} className="gallery-filter transition2"><span>03. </span>Community Center</a>
            <a onClick={() => onClick("DUPLEX-HOUSES")} className="gallery-filter transition2"><span>04. </span>Duplex</a>
            <a onClick={() => onClick("FARM-HOUSES")} className="gallery-filter transition2"><span>05. </span>Farm House</a>
            <a onClick={() => onClick("ROW-BUNGALOWS")} className="gallery-filter transition2="><span>06. </span>Row Bungalows</a>
            <a onClick={() => onClick("TENAMENTS")} className="gallery-filter transition2"><span>07. </span>Tenaments</a>
            <a onClick={() => onClick("TWIN-HOUSES")} className="gallery-filter transition2"><span>08. </span>Twin Houses</a> */