import React, { useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion"

export const PageLoader = () => {

  const db2Controls = useAnimationControls()
  const db1Controls = useAnimationControls()
  const loaderWrapControls = useAnimationControls()

  useEffect(() => { db2Controls.start("indeterminate") }, [])

  const animateDb1 = () => {
    db1Controls.start("indeterminate")
                .then(() => {
                  db1Controls.stop()
                })
                .catch('Error in db1')
    db2Controls.start("afterCompleteAnimation")
                .then(() => {
                  db2Controls.stop()
                })
                .catch('Error in db2')
  }

  const animateSpinner = () => { loaderWrapControls.start("indeterminate").then(() => loaderWrapControls.stop()).catch('Error in Loader Wrap') }
  const db2Variants = {
    initial: {
      width: '100%'
    },
    indeterminate: {
      x: ["-105%", "0%"],
      transition: {
        ease: "easeInOut",
        duration: 1.5,
      }
    },
    afterCompleteAnimation: {
      x: ["0%", "101%"],
      transition: {
        ease: "easeInOut",
        duration: 1.5
      }
    }
  }

  const db1Variants = {
    initial: {
      width: '100%'
    },
    indeterminate: {
      x: "101%",
      transition: {
        ease: "easeInOut",
        duration: 1.5
      }
    }
  }
  
  const loaderWrapVariants = {
    initial: {
      width: '100%'
    },
    indeterminate: {
      x: "101%",
      transition: {
        ease: "easeInOut",
        duration: 1.5
      }
    }
  }

    return(
        <>
            <motion.div
              className="loader-wrap"
              variants={loaderWrapVariants}
              animate={loaderWrapControls}>
                <div className="spinner">
                    <motion.div 
                      className="double-bounce1"
                      variants={db1Variants}
                      initial={"initial"}
                      animate={db1Controls}
                      onAnimationComplete={animateSpinner}
                      />
                    <motion.div
                      className="double-bounce2"
                      variants={db2Variants}
                      initial={"initial"}
                      animate={db2Controls}
                      onAnimationComplete={animateDb1}
                      />
                </div>
            </motion.div>
        </>
    )
}