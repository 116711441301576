/* eslint-disable jsx-a11y/anchor-is-valid */

export const GalleryFilterItems = (props) => {
    // console.log("currentClassName: " + props.currentClassName)
    const { categoryName, handleFilterKeyChange, count, currentClassName } = props
    return (
        <>
            <a 
                onClick={() => handleFilterKeyChange(categoryName.replace(" ", "-"))}
                className={`b ${currentClassName}`}
                >
                    <span>
                        {count <= 9 ? "0"+count : count}. 
                    </span> 
                    {categoryName}
            </a>
        </>
    )
}