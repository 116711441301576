const directory = require.context("./", true, /\.(png|jpe?g|svg)$/);

const getImagePaths = (directory) => {
    let images = [];
    directory.keys().map((item, index) => {
      console.log(item)
      images.push(item.replace("./", ""))
    });
    return images;
  }

let imagePaths = getImagePaths(directory);
// console.log("Images Paths : " + imagePaths)



// const imgCat = Array.from(new Set(imagePaths.map(path => path.split("/")[0])))
// console.log(imgCat)

let images = [];
let categoryImageIndex = 0
let currentCategory = null
imagePaths.forEach((path) => {
  // console.log(path)
  let [catName, projectName, imgName] = path.split("/")
  
  let category = catName.replace(/[0-9]/g, "");
  let imgNameWithoutExt = imgName.split(".")[0];

  // console.log("Img : " + require("./" + path))
  // console.log("Category name : " + category)
  // console.log("Project name : " + projectName)
  // console.log("Image name With Ext : " + imgName)
  // console.log("Image name Without Ext : " + imgNameWithoutExt)
  // console.log("Category Image index : " + categoryImageIndex)

  if(currentCategory === null) {
    currentCategory = category
  } else if(currentCategory !== category) {
    currentCategory = category
    categoryImageIndex = 0
  }

  images.push({
    img: require("./" + path),
    category: category,
    imgNameWithoutExt: imgNameWithoutExt,
    imgNameWithExt: imgName,
    projectName: projectName,
    categoryImageIndex: categoryImageIndex
  })
  categoryImageIndex++
});

const getImagesByCategory = category => {
  // console.log("Get images by category!")
  let images = [];
  let categoryImageIndex = 0
  let currentCategory = null
  
  imagePaths.forEach((path) => {
    
    let [catName, projectName, imgName] = path.split("/")
    catName = catName.replace(/[0-9]/g, "")
    
    if(category === catName.replace(" ", "-")) {
      if(currentCategory === null) {
        currentCategory = catName
      } else if(currentCategory !== catName) {
        currentCategory = catName
        categoryImageIndex = 0
      }
    
      images.push({
        img: require("./" + path),
        category: catName.replace(/[0-9]/g, ""),
        imgName: imgName.split(".")[0],
        imgNameWithExt: imgName,
        projectName: projectName,
        categoryImageIndex: categoryImageIndex
      })
      categoryImageIndex++
    }
  });

  return images
}

const getThumbnailImages = () => {
  
  let thumbnailImages = [];
  let categoryImageIndex = 0
  let currentCategory = null

  imagePaths.forEach((path) => {
    // console.log(path)
    
    let [catName, projectName, imgName] = path.split("/")
  
    let category = catName.replace(/[0-9]/g, "");
    let imgNameWithoutExt = imgName.split(".")[0];
    
    if(imgNameWithoutExt === "THUMBNAIL") {
      
      // console.log("Img : " + require("./" + path))
      // console.log("Category name : " + category)
      // console.log("Project name : " + projectName)
      // console.log("Image name With Ext : " + imgName)
      // console.log("Image name Without Ext : " + imgNameWithoutExt)
      // console.log("Category Image index : " + categoryImageIndex)

      if(currentCategory === null) {
        currentCategory = category
      } else if(currentCategory !== category) {
        currentCategory = category
        categoryImageIndex = 0
      }

      thumbnailImages.push({
        img: require("./" + path),
        category: category,
        imgNameWithoutExt: imgNameWithoutExt,
        imgNameWithExt: imgName,
        projectName: projectName,
        categoryImageIndex: categoryImageIndex
      })
      categoryImageIndex++
    }
  });
  return thumbnailImages
}

const getThumbnailImagesByCategory = (filterkey) => {
  
  let thumbnailImages = [];
  let categoryImageIndex = 0
  let currentCategory = null

  imagePaths.forEach((path) => {
    // console.log(path)
    
    let [catName, projectName, imgName] = path.split("/")
  
    let category = catName.replace(/[0-9]/g, "");
    let imgNameWithoutExt = imgName.split(".")[0];
    
    if(imgNameWithoutExt === "THUMBNAIL" && category.replace(" ", "-") === filterkey) {
      
      // console.log("Img : " + require("./" + path))
      // console.log("Category name : " + category)
      // console.log("Project name : " + projectName)
      // console.log("Image name With Ext : " + imgName)
      // console.log("Image name Without Ext : " + imgNameWithoutExt)
      // console.log("Category Image index : " + categoryImageIndex)

      if(currentCategory === null) {
        currentCategory = category
      } else if(currentCategory !== category) {
        currentCategory = category
        categoryImageIndex = 0
      }

      thumbnailImages.push({
        img: require("./" + path),
        category: category,
        imgNameWithoutExt: imgNameWithoutExt,
        imgNameWithExt: imgName,
        projectName: projectName,
        categoryImageIndex: categoryImageIndex
      })
      categoryImageIndex++
    }
  });
  return thumbnailImages
}

const getProjectImagesByProjectName = currentProjectName => {
  
  let projectImages = []

  // console.log(currentProjectName)
  imagePaths.forEach((path) => {
    // console.log(path)
    
    let [catName, projectName, imgName] = path.split("/")
  
    let category = catName.replace(/[0-9]/g, "");
    let imgNameWithoutExt = imgName.split(".")[0];
    
    if(projectName === currentProjectName) {
      
      // console.log("Img : " + require("./" + path))
      // console.log("Category name : " + category)
      // console.log("Project name : " + projectName)
      // console.log("Image name With Ext : " + imgName)
      // console.log("Image name Without Ext : " + imgNameWithoutExt)
      // console.log("Category Image index : " + categoryImageIndex)

      projectImages.push({
        img: require("./" + path),
        category: category,
        imgNameWithoutExt: imgNameWithoutExt,
        imgNameWithExt: imgName,
        projectName: projectName
      })
    }
  });
  return projectImages
}

const imgCategories = Array.from(new Set(imagePaths.map(path => path.split("/")[0].replace(/[0-9]/g, ""))))
// console.log("Image Categories : " + imgCategories)

export {
  getImagesByCategory,
  images,
  imgCategories,
  getThumbnailImages,
  getThumbnailImagesByCategory,
  getProjectImagesByProjectName
};