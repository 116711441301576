import { useEffect, useState } from "react"

export const ProgressBar = () => {

    const [height, setHeight] = useState({
        barHeight: '0%'
    })

    useEffect(() => {
        window.addEventListener("scroll", () => {
            var documentHeight = document.documentElement.scrollHeight
            var windowHeight = document.documentElement.clientHeight
            var scrollTop = window.scrollY
            var scrollHeight = scrollTop / (documentHeight - windowHeight) * 100
            
            setHeight({
                barHeight: scrollHeight+"%"
            })
        })
    })

    return(
        <>
            <div className="progress-bar-wrap">
                <div className="progress-bar color-bg" style={{'height':height.barHeight}}></div>
            </div>
        </>
    )
}