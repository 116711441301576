/* eslint-disable react-hooks/exhaustive-deps */
import { getProjectImagesByProjectName} from "../../assets/images"
import { useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import { motion } from 'framer-motion'
import './styles.css'


const CustomCarousel = (props) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if(event.key === "Escape"){
        // console.log("Escape pressed")
        handleCloseClick()
      } else if (event.keyCode === 37) {
        // console.log("Left arrow pressed")
        // handlePreviousClick()
      } else if (event.keyCode === 39) {
        // console.log("Right arrow pressed")
        // handleNextClick()
      }
    }

    // Add the event listener
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [])

    const {
      currentProjectName,
      showCarousel,
      setShowCarousel,
      carouselClassName,
      setCarouselClassName,
      currentSlideIndex,
      setCurrentSlideIndex
    } = props
    if(currentProjectName === null) return
    // console.log(filterKey)
    // console.log(showCarousel)
    // console.log(carouselClassName)
    // console.log(currentSlideIndex)
    
    // const filteredImages = getImagesByCategory(filterKey)
    const projectImages = getProjectImagesByProjectName(currentProjectName)

    // .filter((image) => image.category.replace(" ", "-") === filterKey);
    // console.log(projectImages)

    const handleNextClick = () => {
      // console.log("handleNextClick")
      if (currentSlideIndex === projectImages.length - 1) {
        setCurrentSlideIndex(0);
      } else {
        setCurrentSlideIndex(currentSlideIndex + 1);
      }
    };
  
    const handlePreviousClick = () => {
      // console.log("handlePreviousClick")
      if (currentSlideIndex === 0) {
        setCurrentSlideIndex(projectImages.length - 1);
      } else {
        setCurrentSlideIndex(currentSlideIndex - 1);
      }
    };
  
    const handleCloseClick = () => {
      setShowCarousel(false);
      setCarouselClassName("carousel-wrapper hide-carousel")
      setCurrentSlideIndex(0)
    };

    const currentSlideElement = (
      <motion.div
        key={currentSlideIndex}
        className="slide"
        initial={{opacity: 0}}
        animate={{ opacity: 1,
          transition: {
          duration: 0.65,
        } }}
        exit={{ opacity: 0 }}
      >
        <div className="img-wrap">
          <img
            src={projectImages[currentSlideIndex].img}
            alt={projectImages[currentSlideIndex].projectName}
          />
        </div>
      </motion.div>
    );
    
    // otherElems.prevSlideIndex = currentSlideIndex === 0 ? filteredImages.length - 1 : currentSlideIndex - 1

    // const previousSlideElement = (
    //   <div className="slide previous">
    //     <div className="img-wrap">
    //       <img
    //         src={filteredImages[otherElems.prevSlideIndex].img}
    //         alt={filteredImages[otherElems.prevSlideIndex].imgName}
    //       />
    //     </div>
    //   </div>
    // );

    // otherElems.nextSlideIndex = currentSlideIndex === filteredImages.length - 1 ? 0 : currentSlideIndex + 1

    // const nextSlideElement = (
    //   <div className="slide next">
    //     <div className="img-wrap">
    //       <img
    //         src={filteredImages[otherElems.nextSlideIndex].img}
    //         alt={filteredImages[otherElems.nextSlideIndex].imgName}
    //       />
    //     </div>
    //   </div>
    // );

    const carouselElement =
      showCarousel && (
        <div className="carousel">
          <div className="slide-wrapper">
            {currentSlideElement}
          </div>
          <div className="carousel-toolbar">
            <span className="carousel-icon carousel-toolbar-close" onClick={() => handleCloseClick()}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <div className="counter">
              <span>{currentSlideIndex+1} </span>/<span> {projectImages.length}</span>
            </div>
          </div>
          <div className="carousel-actions">
            <div className="carousel-actions-icon icon-prev" onClick={() => handlePreviousClick()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="carousel-actions-icon icon-next" onClick={() => handleNextClick()}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          <div className="carousel-caption">{projectImages[currentSlideIndex].projectName}</div>
        </div>
      );
  
    return (
      <div className={carouselClassName}>
        {/* {imageElements} */}
        {carouselElement}
      </div>
    );
  }


export {CustomCarousel}