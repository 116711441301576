import $ from "jquery"
import React from "react"

export const CountFolio = ({counts}) => {
    
    return(
        <>
            <div className="count-folio">
                <div className="num-album"><span>{counts.categoryImagesCount}</span></div>
                <div className="all-album">{counts.totalImagesCount}</div>
            </div>
        </>
    )
}