/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { ContactUs, Home } from "./Screens";


function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contactUs" element={<ContactUs />} />
        </Routes>
    )
}

export default App;
