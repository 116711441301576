/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from "react";
import { PageLoader, Cursor, ProgressBar, Gallery, GalleryFilters, CountFolio, CustomCarousel, PageSubtitle } from '../../Components';
import { Footer } from "../../Layout";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import Packery from "packery";
import Logo from "../../assets/logos/Logo.png"
import './styles.css'


const Home = () => {

    const [filterKey, setFilterKey] = useState("BUNGALOWS")
    const [currentProjectName, setCurrentProjectName] = useState(null)
    const [totalImagesCount, setTotalImagesCount] = useState(0)
    const [categoryImagesCount, setCategoryImagesCount] = useState(0)
    const isotope = useRef()
    const initPackery = useRef()
    // const isInitialRender = useRef(true)
    const [showCarousel, setShowCarousel] = useState(false)
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
    const [carouselClassName, setCarouselClassName] = useState("carousel-wrapper hide-carousel")
    // const tempImages = getThumbnailImagesByCategory(filterKey)
    // const [categoryThumbnailImgs, setCategoryThumbnailImgs] = useState({
    //     thumbnails: tempImages
    // })

    const handleTotalImageCountChange = (imageCount) => {
        setTotalImagesCount(imageCount)
    }

    const handleCategoryImageCountChange = (categoryImageCount) => {
        setCategoryImagesCount(categoryImageCount)
    }

    const handleFilterKeyChange = (key) => { 
        // console.log("key : " + key)
        // let temp = getThumbnailImagesByCategory(key)
        // setCategoryThumbnailImgs({
        //     ...categoryThumbnailImgs.thumbnails, temp
        // })
        setFilterKey(key)
    }

    const handleImageClick = projectName => {
        // console.log("Handle image click called")
        // console.log("Project name : " + projectName)
        // setCurrentSlideIndex(index)
        setCurrentProjectName(projectName)
        setCarouselClassName("carousel-wrapper")
        setShowCarousel(true)
    }

    const initIsotope = (container) => {
        // console.log(container)
        if(!isotope.current) {
            // console.log("Isotope not initialized")
            initPackery.current = new Packery(container, {
                // filter: `.${filterKey}`,
                itemSelector: ".gallery-item",
                gutter: 0,
                transitionDuration: '1s'
            })
            isotope.current = new Isotope(container)
            // isotope.current.layout()
            return
        }
        // console.log("Isotope already initialized")
    }


    useEffect(() => {
        // if(isInitialRender.current) {
        //     console.log("first time executed")
        //     isInitialRender.current = false
        //     // return
        // }
        // console.log("every another time useEffect executed")
        isotope.current.arrange({
            filter: `.${filterKey}`,
            transitionDuration: '1s'
        })
        isotope.current.arrange()
        setTimeout(() => {
            // console.log("3 seconds completed")
            isotope.current.layout()
        }, 3000)
        // imagesLoaded(".gallery-items", () => {
        //     // console.log("images loaded")
        //     isotope.current.layout()
        // })
    }, [filterKey])

    //show images count

  return (
  <>
        {/* <!--loader start--> */}
        <PageLoader />
        {/* <!--loader end-->  */}
        {/* <!-- main start  --> */}
        <div id="main">
            {/* <!-- main-header--> */}
            <header className="main-header">
                <div onClick={(e) => e.preventDefault()} className="logo-holder ajax">
                    <img src={Logo} alt=""></img>
                </div>
                {/* <!-- nav-button-wrap--> */}
                {/* <div className="nav-button but-hol">
                    <span  className="nos"></span>
                    <span className="ncs"></span>
                    <span className="nbs"></span>
                    <div className="menu-button-text">Menu</div>
                </div> */}
                {/* <!-- nav-button-wrap end-->	 			 */}
                
                {/* Progress Bar */}
                <ProgressBar />
                {/* Progress Bar End*/}
                
                {/* <div className="header_social ">
                    <ul >
                        <li><a href="#" target="_blank">Fb</a></li>
                        <li><a href="#" target="_blank">Ig</a></li>
                        <li><a href="#" target="_blank">Li</a></li>
                    </ul>
                </div> */}
                {/* <!-- share-button --> */}
                <div className="folio-btn showshare">
                    <div className="folio-btn-item">
                        <span className="folio-btn-dot"></span>
                        <span className="folio-btn-dot"></span>
                        <span className="folio-btn-dot"></span>
                        <span className="folio-btn-dot"></span>
                    </div>
                    {/* <span className="folio-btn-tooltip ">Share</span> */}
                </div>
                {/* <!-- share-button end --> */}
            </header>
            {/* <!-- main-header  end--> */}
            {/* <!-- top-header--> */}
            <div className="top-header top-header-half">
                <PageSubtitle />
                {/* <a href="contacts.html" className="single-page-fixed-row-link ajax"><i className="fal fa-envelope"></i> <span>Get In Touch</span></a>			 */}
            </div>
            {/* <!-- top-header  end-->		 */}
            {/* <!-- wrapper  -->	 */}
            <div id="wrapper">
                {/* <!-- content-holder  -->	 */}
                <div className="content-holder" data-pagetitle="Muddassir Shaikh - Architect">
                    {/* <!-- nav-holder--> */}
                    <div className="nav-holder but-hol">
                        <div className="nav-scroll-bar-wrap fl-wrap">
                            <a href="index.html" className="ajax logo_menu">
                                {/* <!-- TO CHANGE --> */}
                                    <img src={Logo} alt=""></img>
                                {/* <!-- TO CHANGE --> */}
                            </a>
                            <div className="nav-container fl-wrap">
                                {/* <!-- nav --> */}
                                <nav className="nav-inner" id="menu">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="contacts.html" className="ajax">Contacts</a></li>
                                    </ul>
                                </nav>
                                {/* <!-- nav end--> */}
                            </div>
                        </div>
                        {/* <!-- nav-title--> */}
                        <div className="nav-holder-line"></div>
                        <div className="nav-title"><span>Menu</span></div>
                        {/* <!-- nav-title end--> */}
                    </div>
                    <div className="nav-holder-dec"></div>
                    <div className="nav-overlay"></div>
                    {/* <!-- nav-holder end -->  */}
                    {/* <!-- content--> */}
                    <div className="content wf-header">
                        {/* <!-- content--> */}
                        <div className="top-btn-holder tbh_mob">
                            <div className="show-filter2"><span>Show Filters</span></div>
                        </div>
                        {/* <!-- filter --> */}
                        <div className="filter-holder fixed-column-filter init_hidden_filter">
                            <GalleryFilters handleFilterKeyChange={handleFilterKeyChange} activeFilter={filterKey} />
                            <CountFolio counts={{totalImagesCount, categoryImagesCount}} />
                            <div className="svg-bg">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%"
                                    height="100%" viewBox="0 0 1600 900" preserveAspectRatio="xMidYMax slice">
                                        
                                    <defs>
                                        <linearGradient id="bg">
                                            <stop offset="0%" style={{stopColor:'rgba(255, 255, 255, 0.6)'}}></stop>
                                            <stop offset="50%" style={{stopColor:'rgba(255, 255, 255, 0.1)'}}></stop>
                                            <stop offset="100%" style={{stopColor:'rgba(255, 255, 255, 0.6)'}}></stop>
                                        </linearGradient>
                                        <path id="wave" stroke="url(#bg)" fill="none" d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
                                            s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z" />
                                    </defs>
                                    <g>
                                        <use xlinkHref="#wave">
                                            <animateTransform attributeName="transform" attributeType="XML" type="translate" dur="10s" calcMode="spline"
                                                values="270 230; -334 180; 270 230" keyTimes="0; .5; 1" keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite" />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform attributeName="transform" attributeType="XML" type="translate" dur="8s" calcMode="spline"
                                                values="-270 230;243 220;-270 230" keyTimes="0; .6; 1" keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite" />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform attributeName="transform" attributeType="XML" type="translate" dur="6s" calcMode="spline"
                                                values="0 230;-140 200;0 230" keyTimes="0; .4; 1" keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite" />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform attributeName="transform" attributeType="XML" type="translate" dur="12s" calcMode="spline" values="0 240;140 200;0 230"
                                                keyTimes="0; .4; 1" keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0" repeatCount="indefinite" />
                                        </use>
                                    </g>
                                </svg>
                            </div>
                            <div className="fwc-dec fwc-dec_top"></div>
                        </div>
                        {/* <!-- filter end-->	 */}
                        {/* <!-- fs-grid-wrap-->	 */}
                        <div className="fs-grid-wrap fs-grid-wrap_column fl-wrap">
                            {/* Gallery */}
                            <Gallery
                                filterKey={filterKey}
                                initIsotope={initIsotope}
                                customFunctions={{handleTotalImageCountChange, handleCategoryImageCountChange, handleImageClick}}
                            />
                            {/* Gallery End */}
                        </div>
                        {/* <!-- fs-grid-wrap end-->	 */}
                        <div className="limit-box fl-wrap"></div>
                        {/* <!-- section--> */}
                        <section className="dark-bg   order-wrap  ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Ready to Order your Project ?</h3>
                                    </div>
                                    {/* <div className="col-md-4"><a href="contacts.html" className="btn flat-btn  transparent-btn  ajax">Get In Touch</a> </div> */}
                                </div>
                            </div>
                        </section>
                        {/* <!-- section end-->	 */}
                    </div>
                    {/* <!-- content end  --> */}
                    <Footer />
                </div>
                {/* <!-- content-holder end --> */}
                {/* <!-- share-wrapper--> */}
                <div className="share-wrapper">
                    <div className="close-share-btn"><i className="fal fa-times"></i></div>
                    <div className="share-container fl-wrap  isShare"></div>
                </div>
                {/* <!-- share-wrapper  end --> */}
            </div>
            {/* <!-- wrapper end --> */}
            {/* <!-- cursor--> */}
            <Cursor />
            {/* <!-- cursor end--> */}
        </div>
        {/* <!-- Main end --> */}
        <CustomCarousel
            currentProjectName={currentProjectName}
            showCarousel={showCarousel}
            carouselClassName={carouselClassName}
            setCarouselClassName={setCarouselClassName}
            setShowCarousel={setShowCarousel}
            currentSlideIndex={currentSlideIndex}
            setCurrentSlideIndex={setCurrentSlideIndex}
        />
      </>
  );
}

export default Home;
