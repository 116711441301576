import { useEffect, useState } from "react"
import { motion } from "framer-motion"

export const Cursor = () => {

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })

    useEffect(() => {
        const mouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }
        window.addEventListener("mousemove", mouseMove);
        return () => { window.removeEventListener("mousemove", mouseMove); }
    }, [])


    const variants = {
        initial: {
            x: '-50%',
            y: '-50%'
        },
        default: {
            x: mousePosition.x,
            y: mousePosition.y
        }
    }

    return(
        <>
            <motion.div
                className="element"
                variants={variants}
                initial="initial"
                animate="default"
            >
                <div className="element-item"></div>
            </motion.div>
        </>
    )
}