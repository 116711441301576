
const Footer = () => {
    return (
        <>
        {/* <!-- footer --> */}
        <div className="height-emulator fl-wrap"></div>
                    <footer className="main-footer">
                        <div className="container">
                            <div className="footer-decor">
                                <canvas id="bg-animation"></canvas>
                            </div>
                            {/* <!-- footer-inner --> */}
                            <div className="footer-inner fl-wrap">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <div className="footer-logo">
                                            {/* <img src={Logo} alt=""></img> */}
                                                <span >Muddassir Shaikh ARCHITECT</span>
                                            </div>
                                        </div>
                                    <div className="col-4 col-md-4">
                                        <div className="footer_text  footer-box fl-wrap">
                                            <p>We design all kind of residential and commercial projects. We also work on redevelopments. We use our specialist construction knowledge and high-level drawing skills to design buildings that are functional, safe, sustainable and aesthetically pleasing. We incorporate our client&#39;s lifestyle and needs in our designs.</p>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4">
                                        <div className="footer_text  footer-box fl-wrap">
                                            <p>We stay involved throughout the construction process. We are always a part of an overall project design team, working closely with a range of construction professionals from quantity surveyors to building services engineers. We co-ordinate with other agencies to complete the project as planned.</p>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4">
                                        <div className="footer-header fl-wrap"><span></span> Contacts</div>
                                        {/* <!-- footer-contacts--> */}
                                        <div className="footer-contacts footer-box fl-wrap">
                                            <ul>
                                                <li><span>Call:</span><a onClick={e => e.preventDefault()}>+91 9662050124</a></li>
                                                <li><span>Write  :</span><a onClick={e => e.preventDefault()}>muddassir_shaikh@yahoo.com</a></li>
                                                {/* <li><span>Find us : </span><a href="#">USA 27TH Brooklyn NY</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- footer-inner end --> */}
                            {/* <!-- subbfooter --> */}
                            <div className="subbfooter fl-wrap">
                                <div className="policy-box">
                                    <span> &#169;  Muddassir Shaikh ARCHITECT 2023 / All rights reserved. </span>
                                </div>
                                <div className="royalty-text">
                                    <span><a href="https://www.fiverr.com/s2/2d4741d6a7">Developed by SHAFFAN SHAIKH</a></span>
                                </div>
                                {/* <div className="footer-solcial">
                                    <ul>
                                        <li><a href="#" target="_blank">Fb</a></li>
                                        <li><a href="#" target="_blank">Tw</a></li>
                                        <li><a href="#" target="_blank">In</a></li>
                                        <li><a href="#" target="_blank">Be</a></li>
                                    </ul>
                                </div> */}
                                <div className="to-top-btn to-top"><i className="fal fa-long-arrow-up"></i></div>
                            </div>
                            {/* <!-- subbfooter end --> */}
                        </div>
                    </footer>
                    {/* <!-- footer end --> */}
        </>
    )
}

export {Footer}