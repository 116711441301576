/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef, useState} from "react"
import { getThumbnailImages } from "../assets/images"
import { ImageLoader } from "./ImageLoader/ImageLoader"

const GridDetItem = (props) => {
    const {imageData} = props
    return (
        <>
            <div className="grid-det-item" onClick={e => e.preventDefault}>
                <a onClick={e => e.preventDefault} className=" grid-det_link">{imageData.projectName}</a>
            </div>
        </>
    )
}

const GridDetCategory = (props) => {
    const {imageData} = props
    return <div className="grid-det_category"><a onClick={e => e.preventDefault}>{imageData.category}</a></div>
}

const GridDetail = (props) => {
    const {imageData} = props
    return (
        <>
            <div className="grid-det lightGallerySelector" href={imageData.img} data-sub-html=".ajax" data-src={imageData.img}>
                <GridDetCategory imageData={imageData} />
                <GridDetItem imageData={imageData} />
            </div>
        </>
    )
}

const GridItemHolder = (props) => {
    const {imageData} = props
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    // setTimeout(() => setIsImageLoaded(true), 6000)

    return (
        <div className="grid-item-holder">
            {/* <LazyLoad height={imageHeight} offset={100} onContentVisible={() => console.log(imageData.imgName)}> */}
            {/* <LazyLoadImage visibleByDefault src={imageData.img} alt={imageData.imgName} afterLoad={() => console.log(imageData.imgName)}/> */}
                {/* <ImageLoader key={imageData.category.toString().replace(" ", "-") + imageData.categoryImageIndex} isImageLoaded={isImageLoaded} /> */}
                <img src={imageData.img} loading="lazy" alt={imageData.projectName} />
            {/* </LazyLoad> */}
            <GridDetail imageData={imageData} />
        </div>
    )
}

const GalleryItem = (props) => {
    const {imageData, handleImageClick} = props

    // console.log("Category: "+imageData.category)
    // console.log("Category image index: "+imageData.categoryImageIndex)

    let className = "gallery-item " + imageData.category.replace(" ", "-");
    return (
        <>
            {/* <div className={className} onClick={() => handleImageClick(imageData.categoryImageIndex)}> */}
            <div className={className} onClick={() => handleImageClick(imageData.projectName)}>
                {/* <GridItemHolder> <img src={img.img} alt={img.imgName} /> <GridDetail> <GridDetCategory className={img.category} /> <GridDetItem className={img.imgName}/> </GridDetail> </GridItemHolder> */}
                <GridItemHolder imageData={imageData} filterKey={props.filterKey} />
            </div>
        </>
    )
}

export const Gallery = ({filterKey, initIsotope, customFunctions}) => {
    
    const reference = useRef(null)
    const thumbnailImages = getThumbnailImages()
    // console.log("Gallery")
    const {handleTotalImageCountChange, handleCategoryImageCountChange, handleImageClick} = customFunctions

    useEffect(() => {
        initIsotope(document.querySelector(".gallery-items"))
        handleTotalImageCountChange(thumbnailImages.length)
        
    }, [])
    useEffect(() => {
        // console.log(reference.current.getElementsByClassName(`${filterKey}`).length)
        // console.log(filterKey)
        handleCategoryImageCountChange(reference.current.getElementsByClassName(`${filterKey}`).length)

    }, [filterKey])

    return(
        <>
            {/* Gallery Start */}
            
            <div ref={reference} className="gallery-items min-pad fl-wrap" id="port-scroll">
                {
                    // imagesByCategory.map((img, index) => {
                    //     return(
                    //             <GalleryItem key={img.category.toString().replace(" ", "-") + img.categoryImageIndex} imageData={img} filterKey={filterKey} handleImageClick={handleImageClick} />
                    //     )
                    // })

                    thumbnailImages.map((img, index) => {
                        // console.log(index + " : " + img)
                        return(
                                <GalleryItem key={img.category.toString().replace(" ", "-") + img.categoryImageIndex} imageData={img} filterKey={filterKey} handleImageClick={handleImageClick} />
                        )
                    })
                }
            </div>
            {/* Gallery End */}
        </>
    )
}